import styles from './CmsEventCalendar.module.scss';
import CmsEventCard from '../CmsEventCard/CmsEventCard';
import React from 'react';
import PropTypes from 'prop-types';

const CmsEventCalendar = ({ items }) => (
  <div className={styles.eventCalendar}>
    {items?.map(event => (
      <CmsEventCard {...event} key={`${event.title}`} />
    ))}
  </div>
);

CmsEventCalendar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      endDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      limitHeading: PropTypes.number,
      limitText: PropTypes.number,
      link: PropTypes.shape({
        target: PropTypes.string,
        rel: PropTypes.string,
        url: PropTypes.string,
      }).isRequired,
      location: PropTypes.string.isRequired,
      startDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]).isRequired,
      title: PropTypes.string.isRequired,
      wienenergieEvent: PropTypes.bool,
    }),
  ),
};

export default CmsEventCalendar;
