import styles from './CmsContactBox.module.scss';
import SocialMenu from '../../../SocialMenu/SocialMenu';
import { CmsList } from '@wienenergiegithub/components';
import React from 'react';
import { Content, Heading, Image, Link } from '@wienenergiegithub/ui-next';
import classNames from 'classnames';

const getSocialMenuSettings = ({ xTwitter, linkedin, mail }) => [
  xTwitter && {
    iconName: 'x-twitter',
    iconType: 'brand',
    title: 'Auf X, früher Twitter, kontaktieren',
    url: xTwitter,
  },
  linkedin && {
    iconName: 'linkedin',
    iconType: 'brand',
    title: 'Auf LinkedIn kontaktieren',
    url: linkedin,
  },
  mail && {
    iconName: 'envelope',
    iconType: 'solid',
    title: 'Per E-Mail kontaktieren',
    url: `mailto:${mail}`,
  },
];

const CmsContactBox = ({
  personname: name,
  personnameIsLink: nameIsLink,
  personnameLink: nameLink,
  jobTitle,
  quote,
  image,
  list,
  socialMedia,
  groupTheme: theme,
  imagePosition,
}) => {
  const variant = theme === 'contrast' ? 'contrast' : 'primary';
  const contactName = nameIsLink ? (
    <Link
      aria-label={nameLink.title}
      className={styles[theme]}
      data-purpose={nameLink.title}
      rel="nofollow noopener"
      target={nameLink.target}
      to={nameLink.url}
    >
      {nameLink.title}
    </Link>
  ) : (
    name
  );
  const { twitter: xTwitter, linkedin, email: mail } = socialMedia || {};
  const links = !list
    ? null
    : list?.map(({ is_link: isLink, text, link }) => (
        <>
          {isLink && (
            <Link
              aria-label={link.title}
              className={styles[theme]}
              data-purpose={link.title}
              rel="nofollow noopener"
              target={link.target}
              to={link.url}
            >
              {link.title}
            </Link>
          )}
          {!isLink && text}
        </>
      ));
  return (
    <div>
      <div
        className={classNames(styles.row, {
          [styles.imageStart]: imagePosition === 'left',
        })}
      >
        <div className={styles.infoSection}>
          <div className={styles.profile}>
            {/* the Heading component is wrapped in a Content, because the Heading can include a Link component, where the styling be the same as if it were in a Content */}
            {/* the alternative to this would have been to copy over the Link (<a>) styling from the Content component to this one */}
            <Content as="span" className={styles.nameContent} theme={theme}>
              <Heading as="h2" className={styles.name} size="h3" theme={theme}>
                {contactName}
              </Heading>
            </Content>
            <span className={classNames(styles.jobTitle, styles[theme])}>
              {jobTitle}
            </span>
          </div>
          {quote && (
            <Content noSpacing theme={theme} withParser>
              {quote}
            </Content>
          )}
          {links && (
            <div className={styles.topics}>
              {quote ? (
                <Heading as="h3" size="h7" theme={theme}>
                  Meine Themen:
                </Heading>
              ) : (
                <Heading as="h3" size="h7" theme={theme}>
                  Für diese Themen setze ich mich ein:
                </Heading>
              )}
              <CmsList
                align="center"
                isDense
                items={links}
                noSpacing
                type="checkmark"
                variant={variant}
              />
            </div>
          )}
          <SocialMenu
            className={styles.socialMenu}
            dense
            justifyContent="start"
            socialMenu={getSocialMenuSettings({
              xTwitter,
              linkedin,
              mail,
            })}
            theme={theme}
          />
        </div>
        {image && (
          <div className={styles.imageSection}>
            <Image
              alt={image.alt}
              className={styles.profilePicture}
              src={image.src}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CmsContactBox;
